module.exports = [{
      plugin: require('/builds/justdanallen/hawks-running/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/justdanallen/hawks-running/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-65V1RW3K4Y","head":false,"anonymize":true},
    },{
      plugin: require('/builds/justdanallen/hawks-running/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
